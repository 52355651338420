import ComingSoon from './Components/ComingSoon';
import Header from './Components/Header';
import './Styles/global.scss';

function App() {
  return (
    <ComingSoon />
    // <Header />
  );
}

export default App;
